<template>
    <div
        tabindex="0"
        class="multiselect relative max-w-full max-h-full outline-none select-none"
        :class="[{ 'cursor-not-allowed': disabled || readonly }, { 'cursor-default': !disabled || !readonly }]"
        :data-id="timeComponentCreated"
        ref="multiselectRef">
        <MultiselectField
            v-if="!props.hideMainInput"
            :selectedItems="selectedItems"
            :maxVisibleChips="maxVisibleChips"
            :placeholder="props.placeholder"
            :placeholderAlwaysVisible="props.placeholderAlwaysVisible"
            :showDropdown="showDropdown"
            :multiple="props.multiple"
            :object="props.object"
            :tags="props.tags"
            :disabled="props.disabled"
            :readonly="props.readonly"
            :labelProp="props.labelProp"
            :valueProp="props.valueProp"
            :timeComponentCreated="timeComponentCreated"
            :maxChipTextWidth="calculatedMaxChipTextWidth"
            :labelName="props.labelName"
            :labelFontSize="props.labelFontSize"
            :labelFontColor="props.labelFontColor"
            :labelFontWeight="props.labelFontWeight"
            :noBorder="props.noBorder"
            :writeInOneLine="props.writeInOneLine"
            :clearSingle="props.clearSingle"
            :miltiselectInFocus="miltiselectInFocus"
            :valid="props.valid"
            :required="props.required"
            :size="props.size"
            :hideToggleIcon="props.hideToggleIcon"
            @clicked="
                () => {
                    emit('clickField');
                }
            ">
            <template v-if="$slots.customField" #customField> <slot name="customField"></slot> </template
        ></MultiselectField>
        <MultiselectDropdown
            v-if="showDropdown && preLoadItemsLoaded !== false"
            :multiple="props.multiple"
            :object="props.object"
            :valueTypeObject="props.valueTypeObject"
            :optionElementsSearch="optionElementsSearch"
            :value="localComponentValue"
            :search="props.search"
            :searchValueProp="searchValue"
            :labelProp="props.labelProp"
            :valueProp="props.valueProp"
            :timeComponentCreated="timeComponentCreated"
            :dropdownDimensions="dropdownDimensions"
            :searchInProcess="searchInProcess || preLoadItemsLoaded === false"
            :searchFunction="props.searchFunction"
            :id="props.id"
            :multiselectFieldHeight="multiselectFieldHeight"
            :groups="groups"
            :groupBy="props.groupBy"
            :creatable="props.creatable"
            :creatableType="props.creatableType"
            :creatableValidationFunction="props.creatableValidationFunction"
            :addNew="props.addNew"
            :groupLabel="props.groupLabel"
            :multiselectRef="multiselectRef"
            :dropdownMaxWidth="props.dropdownMaxWidth"
            :dropdownMinWidth="props.dropdownMinWidth"
            :dropdownMaxHeight="props.dropdownMaxHeight"
            :dropdownWidthEqual="props.dropdownWidthEqual"
            :multiselectFieldWidth="multiselectFieldWidth"
            @bottomVisible="onBottomVisible">
            <template v-if="$slots.prefix" #prefix="{ item }">
                <slot name="prefix" :item="item"></slot>
            </template>
            <template v-if="$slots.content" #content="{ item }">
                <slot name="content" :item="item"></slot>
            </template>
            <template v-if="$slots.suffix" #suffix="{ item }">
                <slot name="suffix" :item="item"></slot>
            </template>
        </MultiselectDropdown>
    </div>
</template>

<script setup>
import { provide, ref, watch, onMounted, onBeforeUnmount, onUpdated, computed } from 'vue';
import MultiselectField from './components/MultiselectField.vue';
import MultiselectDropdown from './components/MultiselectDropdown.vue';
import { getTextWidth, getBodyFontFamily } from '@/helpers/common';
import {
    InjectionKeyToggleSelection,
    InjectionKeyClearSelection,
    InjectionKeyToggleDropdown,
    InjectionKeyToggleGroup,
    InjectionKeyCloseDropdown,
    InjectionKeyHandleSearchChange,
    InjectionKeySetVisibleChips,
    InjectionKeyClickOnTag,
    InjectionKeyAddNew
} from './keys.js';

const props = defineProps({
    value: {
        type: [Object, Array, String, Number, null],
        default: null
    },
    modelValue: {
        type: [Object, Array, String, Number, null],
        default: null
    },
    multiple: {
        type: Boolean,
        default: false
    },
    tags: {
        type: Boolean,
        default: true
    },
    object: {
        type: Boolean,
        default: false
    },
    labelProp: {
        type: String,
        default: 'label'
    },
    valueProp: {
        type: String,
        default: 'id'
    },
    valueTypeObject: {
        type: Boolean,
        default: false
    },
    search: {
        type: Boolean,
        default: false
    },
    searchBy: String,

    hideSelected: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    },
    default: {
        type: Array,
        default: () => []
    },
    label: {
        type: String,
        default: null
    },
    placeholder: {
        type: String,
        default: null
    },
    placeholderAlwaysVisible: {
        type: Boolean,
        default: false
    },
    noBorder: {
        type: Boolean,
        default: false
    },
    items: {
        type: Array,
        default: () => []
    },

    searchFunction: {
        type: Function
    },
    maxChipTextWidth: {
        type: Number,
        default: 120
    },
    id: String,
    groupBy: {
        type: String,
        default: null
    },
    groupLabel: {
        type: String,
        default: null
    },
    creatable: {
        type: Boolean,
        default: false
    },
    creatableType: {
        type: String
    },
    creatableValidationFunction: {
        type: Function
    },
    preLoadItemsFunction: {
        type: Function
    },
    runPreLoadFunctionOnMount: {
        type: Boolean,
        default: false
    },

    labelName: { type: String },
    labelFontSize: { type: String, default: '14px' },
    labelFontColor: { type: String, default: '#131416' },
    labelFontWeight: { type: String, default: '400' },
    writeInOneLine: {
        type: Boolean,
        default: true
    },
    clearSingle: {
        type: Boolean,
        default: true
    },
    valid: {
        type: Boolean,
        default: true
    },
    dropdownWidthEqual: {
        type: Boolean,
        default: false
    },
    dropdownMaxWidth: {
        type: [String, Number]
    },
    dropdownMinWidth: {
        type: [String, Number]
    },
    dropdownMaxHeight: {
        type: [String, Number]
    },
    unfold: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },

    size: {
        type: [String, Number]
    },
    addNew: {
        type: Boolean,
        default: false
    },
    hideToggleIcon: {
        type: Boolean,
        default: false
    },
    pagination: {
        type: Boolean,
        default: false
    },
    blur: {
        type: Boolean,
        default: false
    },
    hideMainInput: {
        type: Boolean,
        default: false
    }
});

let searchLoadedItems = [];

const searchInProcess = ref(false);

let fetchParams = { prevSearchStr: '', limitReached: false };

const fetchData = async searchProp => {
    if (fetchParams.limitReached === true && fetchParams.prevSearchStr === searchProp) {
        return;
    }
    if (fetchParams.prevSearchStr !== searchProp) {
        searchLoadedItems = [];
    }
    searchInProcess.value = true;
    fetchParams.limitReached = false;
    fetchParams.prevSearchStr = searchProp;
    let tempSearchLoadedItems = await props.searchFunction(searchProp, props.id, searchLoadedItems);
    if (Array.isArray(tempSearchLoadedItems)) {
        if (tempSearchLoadedItems.length === 0) {
            fetchParams.limitReached = true;
        } else {
            tempSearchLoadedItems = [
                ...tempSearchLoadedItems.map(el => ({
                    ...el,
                    [props.valueProp]: el.id,
                    [props.labelProp]: el.name
                }))
            ];
            searchLoadedItems = [...searchLoadedItems, ...tempSearchLoadedItems];
        }
    }
    componentItems.value = [...generateComponentItems().filter(el => !searchLoadedItems.map(obj => obj[props.valueProp]).includes(el[props.valueProp])), ...searchLoadedItems];
    searchInProcess.value = false;
};

const timeComponentCreated = performance.now();

if (!Array.isArray(props.items)) {
    console.warn(`MultiSelect id ${timeComponentCreated} WARNING. items in not array`);
}
if (props.object && Array.isArray(props.items) && props.items.length && props.items?.some(el => !el[props.labelProp])) {
    console.warn(`MultiSelect id ${timeComponentCreated} WARNING. props is OBJECT but at least one item do not have element with labelProp equal ${props.labelProp}`);
}
if (Array.isArray(props.items) && props.items.length && props.object && props.items?.some(item => typeof item !== 'object')) {
    console.warn(`MultiSelect id ${timeComponentCreated} WARNING. props is OBJECT but at least one item is not object`);
}
if (!props.object && Array.isArray(props.items) && props.items.length && !props.items?.some(item => typeof item !== 'object')) {
    console.warn(`MultiSelect id ${timeComponentCreated} WARNING. props OBJECT set to false but at least one item is object`);
}
const componentValue = computed(() => props.modelValue || props.value);

const localComponentValue = ref(JSON.parse(JSON.stringify(componentValue.value)));
watch(
    () => componentValue.value,
    () => {
        localComponentValue.value = JSON.parse(JSON.stringify(componentValue.value));
    },
    { deep: true }
);

if (props.multiple && !Array.isArray(localComponentValue.value)) {
    console.warn(`MultiSelect id ${timeComponentCreated} WARNING. props is MULTIPLE but VALUE is not array`);
} else if (!props.multiple && Array.isArray(localComponentValue.value)) {
    console.warn(`MultiSelect id ${timeComponentCreated} WARNING. props MULTIPLE set to false but VALUE is array`);
}

const preLoadItemsLoaded = ref(null);
const preLoadItems = ref([]);
const getPreLoadItems = async () => {
    preLoadItemsLoaded.value = false;
    preLoadItems.value = await props.preLoadItemsFunction();
    preLoadItemsLoaded.value = true;
};

if (props.preLoadItemsFunction && props.runPreLoadFunctionOnMount) {
    getPreLoadItems();
}

const initialItems = computed(() => [
    ...preLoadItems.value,
    ...(props.object
        ? props.items.filter(item => preLoadItems.value.every(preLoadItem => preLoadItem[props.valueProp] !== item[props.valueProp]))
        : props.items.filter(item => preLoadItems.value.every(preLoadItem => preLoadItem !== item)))
]);

const showDropdown = ref(false);

const handleHideSelected = (itemsArray, additional) =>
    props.hideSelected || additional
        ? props.valueTypeObject
            ? itemsArray.filter(el =>
                  props.multiple
                      ? !localComponentValue.value.map(valueEl => valueEl[props.valueProp]).includes(el[props.valueProp])
                      : localComponentValue.value
                        ? localComponentValue.value[props.valueProp] !== el[props.valueProp]
                        : true
              )
            : itemsArray.filter(el => (props.multiple ? !localComponentValue.value.includes(el[props.valueProp]) : localComponentValue.value !== el[props.valueProp]))
        : itemsArray;

const generateComponentItems = () => {
    const notInItems =
        props.multiple && Array.isArray(localComponentValue.value) && localComponentValue.value.length >= 0
            ? props.object
                ? props.valueTypeObject
                    ? localComponentValue.value.filter(el => !initialItems.value.map(itemEl => itemEl[props.valueProp]).includes(el[props.valueProp]))
                    : localComponentValue.value
                          .filter(el => !initialItems.value.map(itemEl => itemEl[props.valueProp]).includes(el))
                          .map(el => ({
                              [props.labelProp]: el,
                              [props.valueProp]: el
                          }))
                : localComponentValue.value.filter(el => !initialItems.value.includes(el))
            : props.object && localComponentValue.value && !Array.isArray(localComponentValue.value)
              ? props.valueTypeObject
                  ? initialItems.value.filter(el => el[props.valueProp] === localComponentValue.value[props.valueProp]).length === 0
                      ? [localComponentValue.value]
                      : []
                  : !initialItems.value.map(itemEl => itemEl[props.valueProp]).includes(localComponentValue.value)
                    ? [
                          {
                              [props.labelProp]: localComponentValue.value,
                              [props.valueProp]: localComponentValue.value
                          }
                      ]
                    : []
              : localComponentValue.value && !initialItems.value.includes(localComponentValue.value)
                ? [localComponentValue.value]
                : [];

    return Array.isArray(initialItems.value) ? [...initialItems.value, ...notInItems] : [];
};

let componentItems = ref(generateComponentItems());

watch(
    () => initialItems.value,
    () => {
        searchValue.value = '';
        componentItems.value = generateComponentItems();
        setOptionElementsSearch();
    }
);

watch(
    () => localComponentValue.value,
    async () => {
        if (searchValue.value === '' && !showDropdown.value) {
            componentItems.value = generateComponentItems();
            setOptionElementsSearch();
            fetchParams.limitReached = false;
        }
    },
    { deep: true }
);

const searchValue = ref('');

const optionElementsSearch = ref([]);
const setOptionElementsSearch = () => {
    optionElementsSearch.value = handleHideSelected(
        componentItems.value.filter(el =>
            (props.object ? el[props.searchBy && el[props.searchBy] ? props.searchBy : props.labelProp] || '' : el)
                .toString()
                .toLowerCase()
                .includes(searchValue.value.toLowerCase())
        )
    );
};

setOptionElementsSearch();

const maxVisibleChips = ref(1);

const selectedItemsFunc = value =>
    props.multiple && Array.isArray(value)
        ? value.length > 0
            ? props.object
                ? props.valueTypeObject
                    ? value
                    : value
                          .filter(valueEl => componentItems.value.find(itemEl => itemEl[props.valueProp] === valueEl))
                          .map(valueEl => componentItems.value.find(itemEl => itemEl[props.valueProp] === valueEl))
                : value
            : []
        : componentItems.value.find(el =>
                props.valueTypeObject ? (value ? el[props.valueProp] === value[props.valueProp] : false) : (props.object ? el[props.valueProp] : el) === value
            )
          ? [componentItems.value.find(el => (props.valueTypeObject ? el[props.valueProp] === value[props.valueProp] : (props.object ? el[props.valueProp] : el) === value))]
          : [];

const selectedItems = computed(() => selectedItemsFunc(localComponentValue.value));

const groups = computed(() => {
    if (!props.groupBy) {
        return null;
    }
    return optionElementsSearch.value.reduce((acc, el) => {
        const existingElement = acc.find(item => item.id === el[props.groupBy]);

        if (!existingElement) {
            acc.push({
                id: el[props.groupBy],
                label: el[props.groupLabel],
                count: optionElementsSearch.value.filter(item => item[props.groupBy] === el[props.groupBy])?.length
            });
        }
        return acc;
    }, []);
});

const calculatedMaxChipTextWidth = ref(props.maxChipTextWidth);

const setVisibleChips = () => {
    const chipsFont = `400 14px ${getBodyFontFamily()}`;
    const gapChips = 8;
    const gapChipLabelIcon = 0;
    const chipPadding = 10;
    const resizeBarrier = 5;
    const widthIconDelete = 24;

    const fieldContainer = document.querySelector(`[data-id="${timeComponentCreated}"].multiselect__field-items`);
    const fieldWidth = fieldContainer?.offsetWidth;
    const plusNLabelLength = getTextWidth(`more ${selectedItems.value.length - maxVisibleChips.value}`, chipsFont);
    if (!props.writeInOneLine) {
        maxVisibleChips.value = selectedItems.value.length;
    } else {
        if (
            maxVisibleChips.value > 1 &&
            selectedItems.value.reduce(
                (acc, el, index) =>
                    index < maxVisibleChips.value
                        ? acc +
                          Math.min(getTextWidth(props.object ? el[props.labelProp] : el, chipsFont), props.maxChipTextWidth) +
                          (!(props.readonly || props.disabled) ? gapChipLabelIcon + widthIconDelete : 0) +
                          chipPadding * 2
                        : acc,
                0
            ) +
                gapChips * (maxVisibleChips.value - 1) +
                (maxVisibleChips.value < selectedItems.value.length ? gapChips + plusNLabelLength : 0) +
                resizeBarrier >=
                fieldWidth
        ) {
            maxVisibleChips.value -= 1;
        }

        if (
            maxVisibleChips.value < selectedItems.value.length &&
            selectedItems.value[maxVisibleChips.value] &&
            selectedItems.value.reduce(
                (acc, el, index) =>
                    index <= maxVisibleChips.value
                        ? acc +
                          Math.min(getTextWidth(props.object ? el[props.labelProp] : el, chipsFont), props.maxChipTextWidth) +
                          (!(props.readonly || props.disabled) ? gapChipLabelIcon + widthIconDelete : 0) +
                          chipPadding * 2
                        : acc,
                0
            ) +
                gapChips * maxVisibleChips.value +
                (maxVisibleChips.value < selectedItems.value.length - 1 ? plusNLabelLength + gapChips : 0) +
                resizeBarrier <
                fieldWidth
        ) {
            maxVisibleChips.value += 1;
        }
    }
    if (maxVisibleChips.value === 1 || !props.writeInOneLine) {
        calculatedMaxChipTextWidth.value = Math.min(
            props.maxChipTextWidth,
            fieldWidth -
                (selectedItems.value.length > 1 && props.writeInOneLine ? plusNLabelLength : 0) -
                (props.multiple ? gapChips + gapChipLabelIcon + widthIconDelete : 0) -
                chipPadding * 2
        );
    }
};

provide(InjectionKeySetVisibleChips, setVisibleChips);

onUpdated(() => {
    if (props.tags) {
        setVisibleChips();
    }
});

const emit = defineEmits(['update:modelValue', 'select', 'change', 'search-change', 'deselect', 'componentApi', 'clickedTag', 'addNew', 'clickField']);

const handleSearchChange = async searchProp => {
    if (props.searchFunction && searchValue.value !== searchProp) {
        if (searchProp !== '') {
            // searchLoadedItems = [];
            await fetchData(searchProp);
        }
    }

    emit('search-change', searchProp);
    searchValue.value = searchProp;

    if (searchProp === '') {
        componentItems.value = generateComponentItems();
    }
    setOptionElementsSearch();
};

provide(InjectionKeyHandleSearchChange, handleSearchChange);

const dropdownDimensions = ref(null);

let multiselectFieldHeight;

const toggleDropdown = event => {
    if (!(props.disabled || props.readonly)) {
        const unfoldButtonsElementsClasses = ['multiselect__append-icon', 'multiselect__custom-field'];
        let unfoldElementClicked = false;
        let currentElement = event?.target;
        while (currentElement) {
            if (unfoldButtonsElementsClasses.some(className => currentElement.classList.contains(className))) {
                unfoldElementClicked = true;
                break;
            }
            currentElement = currentElement.parentElement;
        }
        const multiselectClassesToUnfold = [
            'multiselect',
            'multiselect__field',
            'multiselect__field-items',
            'multiselect__selection-text',
            'muttiselect__chips',
            'muttiselect__chips-counter',
            'multiselect__append-icon',
            'muttiselect__stringed',
            'multiselect__label',
            'multiselect__label-bordered',
            'multiselect__label-noborder'
        ];

        if (!(multiselectClassesToUnfold.some(className => event?.target?.classList.contains(className)) || unfoldElementClicked)) {
            return;
        }
        if (!showDropdown.value) {
            if (props.preLoadItemsFunction && !preLoadItemsLoaded.value && !props.runPreLoadFunctionOnMount) {
                getPreLoadItems();
            }
        }
        showDropdown.value = !showDropdown.value;
    }
};

const closeDropdown = () => {
    showDropdown.value = false;
    if (props.blur) {
        if (JSON.stringify(localComponentValue.value) !== JSON.stringify(componentValue.value)) {
            emit('update:modelValue', localComponentValue.value);
            emit('change', localComponentValue.value, componentValue.value);
        }
    }
};
provide(InjectionKeyCloseDropdown, closeDropdown);

provide(InjectionKeyToggleDropdown, toggleDropdown);

//=================================

const toggleGroup = groupId => {
    if (props.disabled || props.readonly || !props.groupBy || !props.multiple || !props.object || searchInProcess.value) {
        return;
    }
    let newValue;

    if (props.valueTypeObject) {
        const toggledElements = optionElementsSearch.value.filter(el => el[props.groupBy] === groupId);
        const componentValueElementsFromCurrentGroup = localComponentValue.value.filter(el => el[props.groupBy] === groupId);
        const allLocalElementsInFilterResult = toggledElements.every(toggledElement => {
            return componentValueElementsFromCurrentGroup.some(componentElement => componentElement[props.valueProp] === toggledElement[props.valueProp]);
        });

        if (allLocalElementsInFilterResult) {
            newValue = localComponentValue.value.filter(component => {
                return !toggledElements.some(toggledElement => toggledElement[props.valueProp] === component[props.valueProp]);
            });
        } else {
            newValue = [
                ...localComponentValue.value,
                ...toggledElements.filter(toggledElement => !localComponentValue.value.some(component => component[props.valueProp] === toggledElement[props.valueProp]))
            ];
        }
    }
    if (props.valueTypeObject) {
        newValue = newValue.map(el => {
            if (el.multiselectElementIndex !== undefined) {
                const copyEl = JSON.parse(JSON.stringify(el));
                delete copyEl.multiselectElementIndex;
                return copyEl;
            }
            return el;
        });
    }
    if (props.blur && showDropdown.value === true) {
        localComponentValue.value = newValue;
    } else {
        emit('update:modelValue', newValue);
        emit('change', newValue, localComponentValue.value);
    }
    // emit("select", itemFull);
};
//=================================
provide(InjectionKeyToggleGroup, toggleGroup);

const toggleSelection = itemFullOriginal => {
    if (searchInProcess.value) {
        return;
    }
    let itemFull = JSON.parse(JSON.stringify(itemFullOriginal));
    if (props.disabled || props.readonly) {
        return;
    }
    if (props.valueTypeObject && itemFull.multiselectElementIndex !== undefined) {
        delete itemFull.multiselectElementIndex;
    }
    let newValue;
    if (!props.multiple) {
        newValue = props.object ? (props.valueTypeObject ? itemFull : itemFull[props.valueProp]) : itemFull;

        miltiselectInFocus.value = false;
        showDropdown.value = false;
    } else {
        if (Array.isArray(localComponentValue.value) && props.object && props.valueTypeObject) {
            if (localComponentValue.value?.some(el => el[props.valueProp] === itemFull[props.valueProp])) {
                newValue = localComponentValue.value.filter(el => el[props.valueProp] !== itemFull[props.valueProp]);
            } else {
                newValue = [...localComponentValue.value, itemFull];
            }
        } else {
            if (Array.isArray(localComponentValue.value)) {
                const lastIndex = localComponentValue.value.map(el => (props.object ? el == itemFull[props.valueProp] : el == itemFull)).lastIndexOf(true);

                if (lastIndex !== -1) {
                    newValue = [...localComponentValue.value];
                    newValue.splice(lastIndex, 1);
                } else {
                    newValue = [...localComponentValue.value, props.object ? itemFull[props.valueProp] : itemFull];
                }
            }
        }
    }
    if (props.blur && showDropdown.value === true) {
        localComponentValue.value = newValue;
        emit('select', itemFull);
    } else {
        emit('update:modelValue', newValue);
        emit('select', itemFull);
        emit('change', newValue, localComponentValue.value);
    }
};

provide(InjectionKeyToggleSelection, toggleSelection);

const clickOnAddNew = () => {
    showDropdown.value = false;
    emit('addNew');
};
provide(InjectionKeyAddNew, clickOnAddNew);

const clickOnTag = item => {
    emit('clickedTag', item);
};
provide(InjectionKeyClickOnTag, clickOnTag);

const clearSelection = () => {
    if (props.disabled || props.readonly) {
        return;
    }
    localComponentValue.value = props.multiple ? [] : null;
    emit('update:modelValue', props.multiple ? [] : null);
    emit('change', props.multiple ? [] : null, localComponentValue.value);
};
provide(InjectionKeyClearSelection, clearSelection);

const unfoldDropdown = () => {
    const event = { target: multiselectRef.value };
    toggleDropdown(event);
};

const handleKeyDownPress = event => {
    if (!(props.disabled || props.readonly) && ['ArrowDown', 'Enter'].includes(event.key) && !showDropdown.value) {
        unfoldDropdown();
    }
};

async function onBottomVisible() {
    if (!props.pagination) return;
    if (props.searchFunction && !searchInProcess.value) {
        await fetchData(searchValue.value);
        setOptionElementsSearch();
    }
}

const miltiselectInFocus = ref(false);

const focusListener = () => {
    const multiselect = document.querySelector(`[data-id="${timeComponentCreated}"].multiselect`);
    const activeElement = document.activeElement;
    miltiselectInFocus.value = !!multiselect?.contains(activeElement);
};
const handleClickOutsideMultiselectField = event => {
    const multiselect = document.querySelector(`[data-id="${timeComponentCreated}"].multiselect`);
    if (miltiselectInFocus.value && !showDropdown.value && event.target !== multiselect && !multiselect.contains(event.target)) {
        miltiselectInFocus.value = false;
    }
};

onMounted(() => {
    const multiselect = document.querySelector(`[data-id="${timeComponentCreated}"].multiselect`);
    if (multiselect) {
        resizeObserver.observe(multiselect);
        multiselect.addEventListener('keydown', handleKeyDownPress);
        document.addEventListener('focus', focusListener, true);
        document.addEventListener('click', handleClickOutsideMultiselectField);
        initComponentApi();
        emit('componentApi', componentApi);
        if (props.unfold) {
            unfoldDropdown();
        }
    }
});
onBeforeUnmount(() => {
    resizeObserver.disconnect();
    const multiselect = document.querySelector(`[data-id="${timeComponentCreated}"].multiselect`);
    if (multiselect) {
        multiselect.removeEventListener('keydown', handleKeyDownPress);
        document.removeEventListener('focus', focusListener, true);
        document.removeEventListener('click', handleClickOutsideMultiselectField);
    }
});

const multiselectFieldWidth = ref(null);
function getMultiselectFieldWidth() {
    multiselectFieldWidth.value = multiselectRef?.value?.getBoundingClientRect()?.width;
}

const handleResize = entries => {
    window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
            return;
        }
        for (const entry of entries) {
            if (entry.target === multiselectRef.value) {
                getMultiselectFieldWidth();
                if (props.tags) {
                    setVisibleChips();
                }
            }
        }
    });
};

const resizeObserver = new ResizeObserver(handleResize);

const multiselectRef = ref(null);
const focus = () => {
    multiselectRef.value.focus();
    setTimeout(() => {
        miltiselectInFocus.value = true;
    }, 0);
};
let componentApi = {};

const updateItemsApi = async () => {
    if (props.searchFunction && !searchInProcess.value) {
        searchLoadedItems = [];
        await fetchData(searchValue.value);
        setOptionElementsSearch();
    }
};

const initComponentApi = () => {
    componentApi = {
        focus: focus,
        unfold: unfoldDropdown,
        updateItems: updateItemsApi
    };
};
</script>
