<template>
    <div @dblclick="() => $emit('resizeFull')" @mousedown="event => headerMouseDown(event)" class="flex justify-between topmenu-container">
        <div class="flex overflow-hidden">
            <div v-if="title" class="h-full ml-4 text-white align-center mt-[5px] font-medium text-[15px]">
                <span>{{ title }}</span>
            </div>
            <platform-breadcrumb
                :entityId="entityId"
                :instanceId="instanceId"
                :instanceObject="instanceObject"
                v-if="showBreadcrumb && !title"
                :visibleItem="visibleItem"
                :activeColor="activeColor"></platform-breadcrumb>
        </div>
        <div class="flex">
            <create-based-entity-menu :entityId="entityId" :instanceId="instanceId"> </create-based-entity-menu>
            <nav-button
                :items="entityMenuFilteread"
                class="nav-btn modal-top-menu-icons cursor-auto my-2"
                @nav-btn-click="navBtnClick"
                :disabled="!moreBtn"
                :showBreadcrumb="showBreadcrumb">
                <ContextMenu v-if="moreBtnVisible" :event="moreBtnEvent" :items="moreBtn.list" @changedValue="submenuClick" @closeContextMenu="closeMoreMenu"> </ContextMenu>
            </nav-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import { toRaw } from 'vue';
import FavoriteApi from '@/api/favorite';
import CreateBasedEntityMenu from '@/components/basedEntities/CreateBasedEntityMenu.vue';
import NavButton from '@/components/platformSlider/NavButton.vue';
import PlatformBreadcrumb from '@/components/platformBreadcrumb/DynamicBreadcrumb.vue';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import {
    sendRequest
    // hasProperty,
    // copyToClipboard
} from '@/helpers';
// import ability from "@/mixins/ability";
// import EntityApi from "@/api/entity";
export default {
    name: 'ModalEntityMenu',
    // mixins: [ability],
    components: {
        CreateBasedEntityMenu,
        NavButton,
        // SubMenu,
        PlatformBreadcrumb,
        ContextMenu
    },
    props: {
        entityId: {
            type: String
        },
        instanceId: {
            type: String
        },
        instanceObject: {
            type: Object
        },
        moreBtn: {
            type: [Object, Boolean],
            required: true
        },
        target: {
            type: String,
            default: 'editCard'
        },
        useInFavorite: {
            type: Boolean,
            default: false
        },
        useCopyLink: {
            type: Boolean,
            default: true
        },
        showBreadcrumb: {
            type: Boolean,
            default: true
        },
        visibleItem: {
            type: Object
        },
        useInHide: {
            type: Boolean,
            default: false
        },
        isFavorite: {
            type: String,
            required: false
        },
        activeColor: {
            type: String,
            default: '#6e6e6e'
        },
        title: {
            type: String,
            default: ''
        }
    },
    emits: ['btnClick', 'removeFromFavorite', 'resizeFull'],
    data() {
        return {
            favoriteId: null,
            moreBtnVisible: false,
            additionNemu: null,
            entetyMenu: {},
            moreBtnEvent: null,
            menuItems: [
                {
                    id: 'copyLink',
                    icon: 'link-04',
                    viewBox: '0 0 24 24',
                    active: false,
                    tooltip: this.$t('menu.copyLink'),
                    hide: !this.useCopyLink
                },
                {
                    id: 'star',
                    icon: 'bookmark-add-02',
                    active: false,
                    viewBox: '0 0 24 24',
                    tooltip: this.$t('menu.toFavorite'),
                    hide: !this.useInFavorite
                },
                // {
                //   id: "pass",
                //   icon: "pass",
                //   active: false,
                //   tooltip: this.$t("menu.toMyTasks"),
                //   hide: true,
                // },

                {
                    id: 'more',
                    icon: 'dotsHorizontal',
                    viewBox: '0 0 24 24',
                    active: false,
                    tooltip: this.$t('menu.more'),
                    hide: !this.moreBtn?.list?.length
                },
                {
                    id: 'hide',
                    icon: 'tray_24px',
                    viewBox: '0 0 24 24',
                    active: false,
                    tooltip: this.$t('menu.hide'),
                    hide: !this.useInHide
                },
                {
                    id: 'close',
                    // icon: "close2",
                    icon: 'clouse',
                    viewBox: '0 0 24 24',
                    active: false,
                    tooltip: this.$t('menu.close'),
                    hide: false
                }
            ]
        };
    },
    computed: {
        ...mapState(useUserStore, ['user', 'isAdmin']),
        entityMenuFilteread() {
            return this.entityMenuItems.filter(el => !el.hide);
        },
        isNewInstance() {
            return this.instanceId === '-1' || this.instanceId === '-2';
        },
        entityMenuItems() {
            const linkObj = this.menuItems.find(({ id }) => id === 'copyLink');
            if (linkObj) linkObj.hide = !this.useCopyLink;
            const favoriteObj = this.menuItems.find(({ id }) => id === 'star');
            if (favoriteObj) favoriteObj.hide = !this.useInFavorite;
            const moreBtnObj = this.menuItems.find(({ id }) => id === 'more');
            if (moreBtnObj) moreBtnObj.hide = !this.moreBtn?.list?.length;
            const hideObj = this.menuItems.find(({ id }) => id === 'hide');
            if (hideObj) hideObj.hide = !this.useInHide;
            return this.menuItems;
        }
    },
    methods: {
        // generateAvailableEntetyMenuItem() {
        //   if (this.moreBtn) {
        //     let aditionalMenu = [];
        //     this.moreBtn?.list?.forEach((element) => {
        //       if (element.id === "delete") {
        //         if (this.can("delete", "task")) {
        //           aditionalMenu.push(element);
        //         }
        //       } else aditionalMenu.push(element);
        //     });
        //     return aditionalMenu;
        //   } else return [];
        // },
        headerMouseDown(event) {
            if (event.button === 1) {
                this.$emit('btnClick', 'close');
            }
        },
        submenuClick(id) {
            if (id !== 'close') {
                this.$emit('btnClick', id);
                this.closeMoreMenu();
            }
        },
        navBtnClick(e, event) {
            if (e === 'close') {
                this.$emit('btnClick', e);
            }
            if (e === 'copyLink') {
                this.$emit('btnClick', e);
                return;
            }
            if (e === 'hide') {
                this.$emit('btnClick', e);
                return;
            }
            if (!this.isNewInstance) {
                switch (e) {
                    case 'more':
                        this.$emit('btnClick', e);
                        if (this.moreBtn) {
                            this.openMoreMenu(event);
                        }
                        break;

                    case 'star':
                        this.$emit('btnClick', e);
                        if (!this.favoriteId) {
                            this.addEntityToFavorite();
                        } else {
                            this.removeEntityFromFavorite();
                        }
                        break;
                    default:
                        this.submenuClick(e);
                        break;
                }
            }
        },
        async openMoreMenu(event) {
            if (this.moreBtnVisible) {
                this.moreBtnVisible = false;
            } else {
                this.moreBtnEvent = event;
                // await this.getInstanceAbility();
                // this.entetyMenu.list = this.generateAvailableEntetyMenuItem();
                // await this.loadAdditionMenu();
                this.moreBtnVisible = true;
            }
        },
        closeMoreMenu() {
            this.moreBtnVisible = false;
        },
        async checkForFavorite() {
            if (this.entityId && this.instanceId && this.instanceId != '-1' && this.instanceId != '-2') {
                let res = await FavoriteApi.checkIfInstanseInFavorite(this.entityId, this.instanceId);
                const idx = this.entityMenuItems.findIndex(({ id }) => id === 'star');
                if (res?.id) {
                    this.favoriteId = res?.id;
                    this.entityMenuItems[idx].active = true;
                } else {
                    this.favoriteId = null;
                    this.entityMenuItems[idx].active = false;
                }
            }
        },
        async addEntityToFavorite() {
            if (this.entityId && this.instanceId) {
                let favorite = {
                    entity_id: this.entityId,
                    instance_id: this.instanceId,
                    user_id: toRaw(this.user.id)
                };
                let res = await FavoriteApi.addToFavorite(favorite);
                if (res) {
                    this.favoriteId = res?.id;
                    const idx = this.entityMenuItems.findIndex(({ id }) => id === 'star');
                    this.entityMenuItems[idx].active = true;
                    this.$emit('btnClick', {event: "addToFavorite", instanceId: this.instanceId});
                }
            }
        },
        async removeEntityFromFavorite() {
            FavoriteApi.removeFromFavorite(this.favoriteId);
            this.favoriteId = null;
            const idx = this.entityMenuItems.findIndex(({ id }) => id === 'star');
            this.entityMenuItems[idx].active = false;
            this.$emit('removeFromFavorite', this.instanceId);
            this.$emit('btnClick', {event: "removeFromFavorite", instanceId: this.instanceId});
        },
        async loadAdditionMenu() {
            if (this.isAdmin && !this.isNewInstance) {
                this.additionNemu = await sendRequest(`/api/entity_actions/${this.entityId}`, 'get', '');
            }
        },
        async additionalMenuItemClick(item) {
            let req = {
                parameters: {
                    instance_id: this.instanceId
                }
            };
            let res = await sendRequest(`api/code_run/execute/${item.id}`, 'post', req);
            if (res) {
                this.$notify({ type: 'success', title: 'Success', text: 'Code run success' }, 2000);
            }
            console.log(res);
        },
        // async getInstanceAbility() {
        //   if (
        //     this.instanceObject &&
        //     hasProperty(this.instanceObject, "permissions")
        //   ) {
        //     this.setAbility(this.instanceObject?.permissions);
        //   } else {
        //     //  console.warn("no instanceObject in menu", this.instanceObject);
        //     if (this.isNewInstance) {
        //       return;
        //     }
        //     let instanceObj = await EntityApi.getInstance(
        //       this.entityId,
        //       this.instanceId
        //     );
        //     this.setAbility(instanceObj?.permissions);
        //   }
        // },
        async initMenu() {
            if (this.useInFavorite && !this?.isFavorite) {
                await this.checkForFavorite();
            } else {
                this.favoriteId = this.isFavorite;
                const idx = this.entityMenuItems.findIndex(({ id }) => id === 'star');
                this.entityMenuItems[idx].active = true;
            }
        }
    },
    watch: {
        async instanceId() {
            if (this.instanceId !== '-2' && this.instanceId !== '-1') {
                await this.initMenu();
            }
        }
    },
    async created() {
        if (this.instanceId !== '-2' && this.instanceId !== '-1') {
            await this.initMenu();
        }
    },
    sockets: {
        favorite_instance(payload) {
            if (payload.data && this.useInFavorite && this.instanceId && payload.data.instance_id === this.instanceId) {
                const idx = this.entityMenuItems.findIndex(({ id }) => id === 'star');
                if (payload?.event === 'insert' && !this.favoriteId) {
                    this.entityMenuItems[idx].active = true;
                    this.favoriteId = payload.data?.id;
                    return;
                }
                if (payload?.event === 'delete' && this.favoriteId) {
                    this.entityMenuItems[idx].active = false;
                    this.favoriteId = null;
                    return;
                }
            }
        }
    },
    mounted() {},
    unmounted() {}
};
</script>

<style scoped>
.topmenu-container {
    //border-bottom: 1px solid rgb(229, 231, 235);
}
.modal-top-menu-icons {
    margin: 0 !important;
    height: 35px !important;
}
</style>
