<template>
    <div v-for="component in componentItems" :key="component.id" v-show="component.visible">
        <BaseModalDragResize
            v-if="!component.system"
            :modelValue="component.visible"
            :rect="component.stick"
            :wrapperSize="wrapperSize"
            @changeDimensions="data => changeDimensions(component, data)"
            @activateEv="() => setActiveComponent2(component, true)"
            @componentApi="methods => (component.methods = methods)">
            <template #header>
                <ModalTopMenu
                    class="cursor-grab"
                    :class="[isMobile ? '' : 'platform-header-modal-background']"
                    :entityId="component.entityId"
                    :instanceId="component?.props?.id"
                    :useInFavorite="isMobile ? false : component?.useInFavorite"
                    :useCopyLink="isMobile ? false : component?.useCopyLink"
                    :moreBtn="isMobile ? false : component.moreButton"
                    :showBreadcrumb="isMobile ? false : true"
                    :visibleItem="openEntityObj"
                    @btnClick="data => handleModalMenuClick(data, component)"
                    :use-in-hide="isMobile ? false : true"
                    v-bind="component.topProps"
                    v-on="component.topEvents"
                    @resizeFull="
                        () => {
                            $analytics.addEvent({
                                category: 'MultiModalForm',
                                action: 'dblclick',
                                label: 'Resize to full screen',
                                entity: component.name,
                                instance: component.id
                            });
                            if (!isMobile) {
                                showComponent(component.id, true);
                            }
                        }
                    "
                    :is-favorite="component?.isFavorite || ''"
                    activeColor="#131416"
                    :title="component?.componentTitle" />
            </template>
            <template #default>
                <div :style="{ maxHeight: 'calc(100% - 40px)', height: '100%' }">
                    <div class="h-full">
                        <Component
                            :is="component.name"
                            @close="handleModalMenuClick('close', component)"
                            v-bind="getComponentProps(component)"
                            v-on="component.events"
                            @loaded="data => componentOnMounted(data, component)"
                            @forbidden="componentForbiden(component)" />
                    </div>
                </div>
            </template>
        </BaseModalDragResize>

        <BaseModalWindow v-if="component.system" v-bind="component.modalProps" v-on="component.modalEvents" @closeModalWindow="() => removeComponentItemFunc(component)">
            <Component :is="component.name" v-bind="component.props" v-on="component.events" @close="() => removeComponentItemFunc(component)" />
        </BaseModalWindow>
    </div>
    <BaseTray
        v-if="!isMobile"
        :componentItems="componentItems"
        :itemsContextMenu="itemsContextMenu"
        @handleClickContextMenu="handleContextMenu"
        @clickComponent="handleClick"
        @removeComponent="removeComponentItemFunc"
        :parent-width="wrapperSize.width" />
</template>
<script>
import BaseModal from '@/components/ui/BaseModal.vue';
import { mapState, mapActions } from 'pinia';
import { useMultiModalFormStore } from '@/stores/multiModalForm';
import { useUserStore } from '@/stores/userStore';
// import {defineAsyncComponent} from "vue";
import BaseModalWindow from '@/components/ui/BaseModalWindow.vue';
import ModalTopMenu from '@/components/crm/components/modalTopMenu/ModalTopMenu.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseModalDragResize from '@/components/ui/baseModalDragResize/BaseModalDragResize.vue';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import BaseTray from '@/components/ui/BaseTray.vue';
import PageNotFound from '@/pages/PageNotFound.vue';

export default {
    name: 'GlobalModalForm',
    components: {
        BaseTray,
        ContextMenu,
        BaseModalDragResize,
        BaseIcon,
        BaseButton,
        ModalTopMenu,
        BaseModalWindow,
        BaseModal
    },
    props: {},
    data() {
        return {
            openEntityObj: {},
            openModal: false,
            loadMainComponent: false,
            wrapperSize: {
                width: 1000,
                height: 1000
            },
            contextMenuVisible: false,
            itemsContextMenu: [
                {
                    id: 'all_hide',
                    label: 'Hide all',
                    type: 'button',
                    icon: 'zoomMinus'
                },
                {
                    id: 'all_close',
                    label: 'Close all',
                    type: 'button',
                    icon: 'crossNew'
                }
            ],
            eventContextMenu: null,
            hoverComponent: null,
            timeout: null
        };
    },
    watch: {
        $route(newValue, oldValue) {
            if (newValue.name !== oldValue.name) {
                this.$modal.hideAll();
            }
        }
    },
    computed: {
        ...mapState(useMultiModalFormStore, ['componentItems']),
        ...mapState(useUserStore, ['isMobile'])
    },
    methods: {
        ...mapActions(useMultiModalFormStore, ['removeComponentItem', 'hideComponent', 'showComponent', 'setActiveComponent', 'compOnMount', 'setWrapperSize']),
        getComponentProps(component) {
            return {
                wrapperSize: component.stick,
                ...component.props
            };
        },
        handleModalMenuClick(data, component) {
            if (data === 'close') {
                this.handleEventAnalytics('closeForm', component);
                this.removeComponentItem(component.id);
                component.onClose();
                return;
            }
            if (data === 'hide') {
                this.handleEventAnalytics('hideForm', component);
                this.hideComponent(component.id);
                component.onHide();
                return;
            }
            if (data === 'copyLink') {
                this.handleEventAnalytics('saveLink', component, { link: component.link });
                this.saveTaskLink(component.link);
                return;
            }
            if (data === 'star') {
                this.handleEventAnalytics('addToFav', component);
                return;
            }
            if (data === 'more') {
                this.handleEventAnalytics('moreFunc', component);
                return;
            }
            component.moreFunction(data);
            component.btnClick(data);
        },
        handleClick(component) {
            if (component.visible) {
                this.$analytics.addEvent({
                    category: 'MultiModalForm',
                    action: 'click button hide with tray',
                    label: 'Hide modal form with tray',
                    entity: component.name,
                    instance: component?.props?.id || component.id,
                    data: { link: component.link }
                });
                this.hideComponent(component.id);
            } else {
                this.$analytics.addEvent({
                    category: 'MultiModalForm',
                    action: 'click button show with tray',
                    label: 'Show modal form with tray',
                    entity: component.name,
                    instance: component?.props?.id || component.id,
                    data: { link: component.link }
                });
                this.showComponent(component.id);
            }
        },
        removeComponentItemFunc(component) {
            this.removeComponentItem(component.id);
            component.onClose();
        },
        componentOnMounted(data, component) {
            this.compOnMount(data, component);
        },
        changeDimensions(component, data) {
            if (!this.isMobile) {
                component.stick.radius = true;
                component.stick.top = data.top;
                component.stick.left = data.left;
                component.stick.width = data.width;
                component.stick.height = data.height;
            }
        },
        setActiveComponent2(component, value) {
            this.setActiveComponent(component, value);
        },
        handleContextMenu(data) {
            if (data?.id === 'all_hide') {
                this.$analytics.addEvent({
                    category: 'MultiModalForm',
                    action: 'click',
                    label: 'Hide all in context menu',
                    entity: '',
                    instance: ''
                });
                this.componentItems.forEach(el => {
                    this.hideComponent(el.id);
                });
            }
            if (data?.id === 'all_close') {
                this.$analytics.addEvent({
                    category: 'MultiModalForm',
                    action: 'click',
                    label: 'Close all in context menu',
                    entity: '',
                    instance: ''
                });
                this.componentItems.forEach(el => {
                    this.removeComponentItem(el.id);
                    el.onClose();
                });
            }
        },
        updateWrapperSize() {
            this.wrapperSize.width = document.body.clientWidth; // window.innerWidth;
            this.wrapperSize.height = document.body.clientHeight; // window.innerHeight;
            this.setWrapperSize(this.wrapperSize);
        },
        async saveTaskLink(link) {
            try {
                await navigator.clipboard.writeText(link);
                this.$notify({
                    type: 'success',
                    title: this.$t('notifications.success'),
                    text: this.$t('notifications.URLCopiedToClipboard')
                });
            } catch (err) {
                console.error('Failed to copy: ', err);
                this.$notify({
                    type: 'error',
                    title: this.$t('notifications.error'),
                    text: this.$t('notifications.AnErrorOccurredWhileCopyingTheURL')
                });
            }
        },
        keydownActiveComponent(event) {
            if (event.key === 'Escape' || event.keyCode === 27) {
                const component = this.componentItems.find(el => el.visible && el?.stick?.active);
                if (!component) return;
                this.removeComponentItem(component.id);
                component.onClose();
            }
        },
        componentForbiden(component) {
            component.name = PageNotFound;
            component.title = 'Page not found';
            component.props = {};
            component.events = {};
        },
        handleEventAnalytics(eventKey, component, data = null) {
            const labelsData = {
                hideForm: 'Hide modal form',
                closeForm: 'Close modal form',
                saveLink: 'Save link to clipboard',
                moreFunc: 'Open list of more functions',
                addToFav: 'Add to favorite'
            };
            if (!(eventKey in labelsData)) {
                return;
            }
            this.$analytics.addEvent({
                category: 'MultiModalForm',
                action: 'lmbc',
                label: labelsData[eventKey],
                entity: component.name,
                instance: component?.props?.id || component.id,
                data: data
            });
        }
    },
    mounted() {
        this.updateWrapperSize();
        window.addEventListener('resize', this.updateWrapperSize);
        window.addEventListener('keydown', this.keydownActiveComponent);

        window.addEventListener('orientationchange', () => {
            this.updateWrapperSize();
        });
    },
    unmounted() {
        window.removeEventListener('resize', this.updateWrapperSize);
        window.addEventListener('keydown', this.keydownActiveComponent);
    }
};
</script>

<style lang="scss" scoped>
.context-menu {
    position: absolute;
    bottom: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 0;
    list-style-type: none;
}

.context-menu ul {
    margin: 0;
    padding: 0;
}

.context-menu li {
    padding: 5px 10px;
    cursor: pointer;
}

.context-menu li:hover {
    background-color: #f0f0f0;
}
</style>
