<template>
    <div
        v-if="$slots.customField"
        class="multiselect__custom-field w-fit"
        @click="
            event => {
                toggleDropdown(event);
                emit('clicked');
            }
        ">
        <slot name="customField"></slot>
    </div>

    <div v-else class="flex w-full h-full flex-col max-w-full max-h-full box-border">
        <!-- @click="toggleDropdown" -->
        <div
            v-if="props.labelName"
            class="multiselect__label block relative cursor-default"
            :class="[{ 'multiselect_has-value': selectedItems.length }]"
            :style="{
                color: props.labelFontColor,
                fontSize: props.labelFontSize,
                fontWeight: props.labelFontWeight
            }">
            <div v-if="noBorder" :class="[{ 'multiselect__label-noborder': props.noBorder }]">{{ props.labelName }}<span v-if="props.required" class="text-[#FF7C73]">*</span></div>
            <div class="multiselect__label-bordered" :class="[{ invisible: props.noBorder }]">{{ props.labelName }}<span v-if="props.required" class="text-[#FF7C73]">*</span></div>
        </div>

        <div
            @click="
                event => {
                    if (showRemainingValuesModal && !(props.disabled || props.readonly)) {
                        showRemainingValuesModal = false;
                    }
                    toggleDropdown(event);
                    emit('clicked');
                }
            "
            @mouseenter="onMultiselectFieldMouseEnter"
            @mouseleave="onMultiselectFieldMouseLeave"
            class="multiselect__field group/multiselect flex items-center relative w-full h-full max-w-full max-h-full transition ease-out"
            :style="{
                minHeight: minFieldHeight,
                paddingTop: isSmallSize ? '4px' : '7px',
                paddingBottom: isSmallSize ? '4px' : '7px'
            }"
            :class="[
                { 'bg-[var(--sp-bg-text-field-disabled)]': props.disabled },
                { 'multiselect_has-value': selectedItems.length },
                { 'ring-1 ring-inset rounded-[12px]': !props.noBorder },
                {
                    'ring-[#FF7C73] hover:ring-[#FF7C73]': !props.valid && !props.noBorder
                },
                {
                    'border-b border-b-[#FF7C73]': !props.valid && props.noBorder
                },
                {
                    'ring-[#F9F9F9] bg-[#F9F9F9] hover:ring-[#979BB2]': !props.noBorder && !props.showDropdown && !props.miltiselectInFocus
                },
                {
                    'ring-[#519DF5] bg-[#FFFFFF]': !props.noBorder && props.showDropdown
                },
                {
                    'ring-[#979BB2] bg-[#F9F9F9]': !props.noBorder && props.miltiselectInFocus && !props.showDropdown
                },
                {
                    // 'group-hover:border-b group-hover:border-b-[var(--sp-border-color-blue-hover)] group-hover:border-t group-hover:border-t-transparent':
                    'border-y-2 border-b-[#F9F9F9] border-t-transparent hover:border-b-[#979BB2]': props.noBorder && !props.showDropdown && !props.miltiselectInFocus
                },
                {
                    'border-y-2 border-b-[#519DF5] border-t-transparent':
                        (props.noBorder && props.showDropdown) || (props.miltiselectInFocus && !props.showDropdown && props.noBorder)
                }
            ]">
            <span
                v-if="(props.placeholder && !selectedItems.length) || (props.placeholderAlwaysVisible && props.placeholder && selectedItems.length)"
                class="multiselect__placeholder"
                >{{ props.placeholder }}</span
            >
            <div
                class="multiselect__field-items flex max-w-full max-h-full overflow-hidden"
                :class="[{ 'ml-0': props.noBorder }, { 'mx-2': !props.noBorder }]"
                :style="[
                    props.writeInOneLine
                        ? {
                              textOverflow: 'ellipsis',
                              overflow: 'hidden'
                          }
                        : { overflowWrap: 'anywhere', overflow: 'hidden' }
                ]"
                :data-id="props.timeComponentCreated">
                <div
                    v-if="props.tags"
                    class="muttiselect__chips flex w-full"
                    :style="{ columnGap: '8px', rowGap: isSmallSize ? '4px' : '8px' }"
                    :class="[{ 'flex-wrap': !props.writeInOneLine }]">
                    <MultiselectFieldChip
                        v-for="item in selectedItems.slice(0, maxVisibleChips)"
                        :key="item"
                        :item="item"
                        :disabled="props.disabled"
                        :readonly="props.readonly"
                        :multiple="props.multiple"
                        :labelProp="props.labelProp"
                        :valueProp="props.valueProp"
                        :object="props.object"
                        :maxChipTextWidth="props.maxChipTextWidth"
                        :chipHeight="innerElementHeight" />

                    <span
                        v-if="maxVisibleChips < selectedItems.length"
                        @mouseenter="onMultiselectMoreMouseEnter"
                        @mouseleave="onMultiselectMoreMouseLeave"
                        class="muttiselect__chips-counter"
                        :class="[{ 'cursor-not-allowed': props.disabled }, { 'cursor-default': !props.disabled }]"
                        >{{ $t('multiSelect.andMore') }} {{ selectedItems.length - maxVisibleChips }}</span
                    >
                </div>
                <span
                    v-else
                    class="muttiselect__stringed cursor-default pl-2"
                    :class="[
                        // { 'text-xs': !props.noBorder },
                        { 'cursor-not-allowed': disabled || readonly },
                        { 'cursor-default': !disabled || !readonly },
                        {
                            'whitespace-nowrap overflow-hidden text-ellipsis': props.writeInOneLine
                        }
                    ]">
                    {{
                        selectedItems.length
                            ? selectedItems.reduce(
                                  (acc, el) => (acc === '' ? (props.object ? el[props.labelProp] : el) : acc + ', ' + (props.object ? el[props.labelProp] : el)),
                                  ''
                              )
                            : ''
                    }}
                </span>
            </div>
            <div
                v-if="selectedItems.length && (props.multiple || props.clearSingle) && !(props.readonly || props.disabled)"
                class="multiselect__clear-icon-wrapper mb-auto flex items-center"
                :style="{
                    minHeight: innerElementHeight,
                    marginTop: controlButtonsMarginTop
                }">
                <BaseIcon
                    @click="clearSelection"
                    class="shrink-0 h-[24px] w-[24px]"
                    :class="[
                        {
                            'fill-[#C0C2CD] hover:fill-[#C0C2CD] hidden group-hover/multiselect:inline-block': !props.showDropdown
                        },
                        {
                            'fill-[#519DF5] hover:fill-[#519DF5] inline-block': props.showDropdown
                        },
                        {
                            'cursor-pointer': !props.disabled
                        }
                    ]"
                    name="clouse" />
            </div>
            <div
                v-if="!(props.readonly || props.disabled || props.hideToggleIcon)"
                class="multiselect__append-icon-wrapper mb-auto flex items-center"
                :style="{
                    minHeight: innerElementHeight,
                    marginTop: controlButtonsMarginTop
                }">
                <BaseIcon
                    class="multiselect__append-icon shrink-0 mr-2 fill-none h-[24px] w-[24px]"
                    :class="[
                        { 'multiselect__append-icon-dropdown-active': props.showDropdown },
                        {
                            'stroke-[#C0C2CD] hover:stroke-[#C0C2CD]': !props.showDropdown
                        },
                        {
                            'stroke-[#519DF5] hover:stroke-[#519DF5]': props.showDropdown
                        },
                        {
                            'cursor-pointer': !props.disabled
                        }
                    ]"
                    name="arrow_down" />
            </div>
        </div>

        <SmartModal v-if="showRemainingValuesModal && selectedItems.length && !props.showDropdown" :target="modalTarget" @close="showRemainingValuesModal = false">
            <div class="p-2 flex flex-wrap gap-2 w-full select-none" @mouseenter="onModalMouseEnter" @mouseleave="onModalMouseLeave">
                <MultiselectFieldChip
                    v-for="item in selectedItems.slice(maxVisibleChips)"
                    :key="item"
                    :item="item"
                    :disabled="props.disabled"
                    :readonly="props.readonly"
                    :multiple="props.multiple"
                    :labelProp="props.labelProp"
                    :valueProp="props.valueProp"
                    :object="props.object"
                    class="max-w-full" />
            </div>
        </SmartModal>
    </div>
</template>

<script setup>
import { ref, inject, onBeforeUnmount, computed } from 'vue';
import { InjectionKeyClearSelection, InjectionKeyToggleDropdown, InjectionKeySetVisibleChips } from '../keys.js';
const clearSelection = inject(InjectionKeyClearSelection);
const toggleDropdown = inject(InjectionKeyToggleDropdown);
const setVisibleChips = inject(InjectionKeySetVisibleChips);
import MultiselectFieldChip from './MultiselectFieldChip.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import SmartModal from '@/components/ui/SmartModal.vue';

const props = defineProps({
    selectedItems: {
        type: [Object, Array, String, Number, null],
        default: null
    },
    maxVisibleChips: {
        type: [Number, null],
        default: Number
    },
    index: {
        type: [Number],
        default: Number
    },
    placeholder: {
        type: String,
        default: null
    },
    placeholderAlwaysVisible: {
        type: Boolean,
        default: false
    },
    tags: {
        type: Boolean,
        default: true
    },

    showDropdown: {
        type: Boolean,
        default: true
    },
    multiple: {
        type: Boolean,
        default: false
    },
    labelProp: {
        type: String,
        default: 'label'
    },
    valueProp: {
        type: String,
        default: 'id'
    },
    object: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    },
    noBorder: {
        type: Boolean,
        default: false
    },
    miltiselectInFocus: {
        type: Boolean,
        default: false
    },
    timeComponentCreated: {
        type: Number,
        default: null
    },
    maxChipTextWidth: Number,
    labelName: { type: String },
    labelFontSize: { type: String, default: '14px' },
    labelFontColor: { type: String, default: '#131416' },
    labelFontWeight: { type: String, default: '400' },
    writeInOneLine: {
        type: Boolean,
        default: true
    },
    clearSingle: {
        type: Boolean,
        default: true
    },
    valid: {
        type: Boolean,
        default: true
    },
    required: {
        type: Boolean,
        default: false
    },

    size: {
        type: [String, Number],
        default: '44'
    },
    hideToggleIcon: {
        type: Boolean,
        default: false
    }
});
const recalculateTags = () => {
    setVisibleChips();
};

const minFieldHeight = computed(() => {
    return (parseInt(props.size) && parseInt(props.size) + 'px') || '44px';
});

const isSmallSize = computed(() => {
    return parseInt(minFieldHeight.value) < 44;
});

const innerElementHeight = computed(() => {
    return isSmallSize.value ? '24px' : '30px';
});

const controlButtonsMarginTop = computed(() => {
    return Math.max((Math.max(parseInt(minFieldHeight.value), 32) - (isSmallSize.value ? 8 : 14) - parseInt(innerElementHeight.value)) / 2, 0) + 'px';
});

const showRemainingValuesModal = ref(false);
let timerPopupOpen;
let timerPopupClose;
let modalTarget = null;

const showRemainingValuesModalFunc = () => {
    clearTimeout(timerPopupClose);
    if (!showRemainingValuesModal.value) {
        timerPopupOpen = setTimeout(() => {
            if (!props.showDropdown) {
                showRemainingValuesModal.value = true;
            }
        }, 200);
    }
};

const hideRemainingValuesModalFunc = () => {
    clearTimeout(timerPopupOpen);
    if (showRemainingValuesModal.value) {
        timerPopupClose = setTimeout(() => {
            if (showRemainingValuesModal.value) {
                showRemainingValuesModal.value = false;
            }
            if (props.tags) {
                recalculateTags();
            }
        }, 200);
    }
};

const onMultiselectFieldMouseEnter = () => {
    if (props.tags) {
        recalculateTags();
    }
};
const onMultiselectFieldMouseLeave = () => {
    if (props.tags && !showRemainingValuesModal.value) {
        recalculateTags();
    }
};
const onMultiselectMoreMouseEnter = event => {
    modalTarget = event.target;

    if (!props.showDropdown) {
        showRemainingValuesModalFunc();
    }
};
const onMultiselectMoreMouseLeave = () => {
    hideRemainingValuesModalFunc();
};
const onModalMouseEnter = () => {
    clearTimeout(timerPopupClose);
};
const onModalMouseLeave = () => {
    hideRemainingValuesModalFunc();
};

const emit = defineEmits(['clicked']);
onBeforeUnmount(() => {
    clearTimeout(timerPopupOpen);
    clearTimeout(timerPopupClose);
});
</script>

<style lang="scss" scoped>
// .multiselect__field {
//   min-height: 36px;
//   display: flex;
//   align-items: center;
//   border-radius: 4px;
//   border: 1px solid #e8e8e8;
//   position: relative;
// }

.multiselect__field-items {
    display: flex;
    width: 100%;
    // margin: 6px;
}

// .multiselect__label {
//   position: absolute;
//   top: -8px;
//   left: 20px;
//   font-size: 10px;
//   line-height: 10px;
//   padding: 0 10px;
//   border: 1px #3a354199 solid;
//   background-color: #f4f4f4;
//   border-radius: 2px;
// }

.multiselect__selection {
    margin-right: 2px;
}

.multiselect__placeholder {
    position: absolute;
    width: fit-content;
    top: 50%;
    left: 1rem;
    right: 1.5rem;
    transform: translateY(-50%);
    // font-weight: 500;
    color: var(--bms-placeholder-color, #c1c1c1);
    transition:
        transform 250ms ease-out,
        top 250ms ease-out,
        font-size 250ms ease-out;
    pointer-events: none;
    // z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
    // font-size: 10px;
}
.multiselect_has-value .multiselect__placeholder {
    top: 0;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 0.8rem;
    line-height: 1;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 58%, rgba(255, 255, 255, 0) 58%);
    border-radius: 4px;
    padding: 2px;
    color: var(--sp-text-third, #c1c1c1);
}

.muttiselect__chips {
    // display: flex;
    // gap: 8px;
}
.muttiselect__chips-counter {
    align-items: center;
    display: flex;
    white-space: nowrap;
    color: #bdbecd;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.muttiselect__stringed {
    color: var(--sp-text-primary);
    font-size: 14px;
}

.multiselect__append-icon {
    transition: transform 0.1s;
}
.multiselect__append-icon-dropdown-active {
    transform: rotate(180deg);
}
.multiselect__label-noborder {
    transition: transform 100ms;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(80%);
    // z-index: 1;
}
.multiselect_has-value .multiselect__label-noborder {
    transform: translateY(0);
    left: 0;
    // z-index: 1;
}
</style>
