<template>
    <div class="datepicker__wrapper w-full relative">
        <p v-if="label" class="datepicker__label">
            {{ label }}
            <span v-if="required" class="text-[#FF7C73]"> * </span>
        </p>
        <div class="datepicker__container-wrapper" :class="{ 'cursor-not-allowed': readonly }">
            <div
                class="datepicker__container cursor-pointer"
                :class="{
                    'pointer-events-none': readonly,
                    'border-[#C0C2CD]': valid,
                    'border-[#FF7C73]': !valid,
                    'cursor-pointer': !readonly,
                    'cursor-default': readonly,
                    'custom-border': noBorder,
                    'active-border': showCalendarModal
                }"
                @click="toggleCalendarModal"
                :style="containerStyle"
                ref="datepickerContainer">
                <input type="text" class="active-date__input pointer-events-none" :placeholder="'DD.MM.YYYY HH:mm'" :value="inputValue" :disabled="readonly" />

                <div class="max-h-6 shrink-0">
                    <button type="button" data-action="openCalendar" class="w-6 h-6 flex items-center justify-center" :disabled="readonly">
                        <BaseIcon class="button__icon fill-[#C0C2CD] h-5 w-5 hover:fill-[#519DF5]" name="calendarNew"></BaseIcon>
                    </button>
                </div>
            </div>

            <button
                type="button"
                v-if="showResetButton && Boolean(componentValue) && !readonly"
                class="reset__button cursor-pointer w-6 h-6 flex items-center"
                :style="{ bottom: resetButtonPosition }"
                @click="handleReset()"
                :disabled="readonly">
                <BaseIcon class="button__icon h-6 w-6" name="close"></BaseIcon>
            </button>
        </div>
        <SmartModal v-if="showCalendarModal" :target="datepickerContainer" @close="closeCalendarModal">
            <div class="datepicker__modal-container flex flex-col">
                <DatePicker v-if="!range" v-model.number="datePickerValue" v-bind="calendarProps" :rules="rules" />
                <DatePicker v-if="range" v-model.range.number="datePickerValue" v-bind="calendarProps" :rules="rules" />
                <div class="datepicker__confirm-buttons p-4 flex gap-4">
                    <BaseButton color="secondary" @click="closeCalendarModal" :label="$t('buttons.cancel')" class="w-[100px]" />
                    <BaseButton @click="onSave" :label="$t('buttons.save')" class="w-[100px]" />
                </div>
            </div>
        </SmartModal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, PropType, onMounted } from 'vue';
import { DatePicker } from 'v-calendar';
import { useUserStore } from '@/stores/userStore';
import dayjs from 'dayjs';
import 'v-calendar/dist/style.css';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import SmartModal from '@/components/ui/SmartModal.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import { BaseComponentSizeModel } from '@/models/base-component-size.model';

export default defineComponent({
    name: 'BaseDatePicker',
    components: { BaseIcon, DatePicker, SmartModal, BaseButton },
    props: {
        label: { type: String },
        value: { type: [Number, String, Date, Array, Object] as PropType<number | string | Date | Array<any> | object> },
        modelValue: { type: [Number, String, Date, Array, Object] as PropType<number | string | Date | Array<any> | object> },
        readonly: { type: Boolean, default: false },
        showResetButton: { type: Boolean, default: true },
        valid: { type: Boolean, default: true },
        dateTime: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        size: String as PropType<BaseComponentSizeModel | string>,
        range: { type: Boolean, default: false },
        noBorder: { type: Boolean, default: false },
        onlyWorkingHours: { type: Boolean, default: false }
    },
    emits: ['update', 'update:modelValue'],

    setup(props, { emit }) {
        const componentValue = ref<any>('');
        const datePickerValue = ref<any>('');
        const showCalendarModal = ref(false);
        const datepickerContainer = ref(null);
        const userStore = useUserStore();

        const workHours = ref([8, 9, 10, 11, 12, 13, 14, 15, 16, 17]);

        const rules = computed(() => {
            return props.onlyWorkingHours
                ? {
                      hours: workHours.value
                  }
                : {};
        });

        const masks = computed(() => ({ input: formatDate.value }));
        const formatDate = computed(() => (props.dateTime && !props.range ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'));

        const currentLanguage = computed(() => userStore.user?.lang_id?.slice(0, 2) || 'en');

        const inputValue = computed(() => {
            if (componentValue.value && Number.isInteger(componentValue.value)) {
                return dayjs(componentValue.value).format(formatDate.value);
            }
            if (typeof componentValue.value === 'object' && componentValue.value !== null && Object.keys(componentValue.value).length) {
                return `${dayjs(componentValue.value.start).format(formatDate.value)} - ${dayjs(componentValue.value.end).format(formatDate.value)}`;
            } else return '';
        });

        const calendarMode = computed(() => (props.dateTime && !props.range ? 'dateTime' : 'date'));
        const propsValue = computed(() => props.value || props.modelValue);

        const calendarProps = computed(() => ({
            mode: calendarMode.value,
            range: props.range,
            isRequired: true,
            is24hr: true,
            locale: currentLanguage.value,
            firstDayOfWeek: 2,
            masks: { weekdays: 'WW' }
        }));

        const sizeMap: Record<BaseComponentSizeModel, { height: string; borderRadius: string }> = {
            sm: { height: '38px', borderRadius: '12px' },
            md: { height: '44px', borderRadius: '12px' },
            lg: { height: '50px', borderRadius: '16px' }
        };

        const sizeConfig = computed(() => {
            if (props.size && parseInt(props.size)) {
                const customSize = parseInt(props.size);
                return { height: `${customSize}px`, borderRadius: '12px' };
            }
            return sizeMap[props.size as BaseComponentSizeModel] || sizeMap.md;
        });

        const containerStyle = computed(() => ({
            minHeight: sizeConfig.value.height,
            height: sizeConfig.value.height,
            borderRadius: sizeConfig.value.borderRadius
        }));

        const resetButtonPosition = computed(() => (parseInt(sizeConfig.value.height) - 24) / 2 + 'px');

        watch(propsValue, newVal => {
            if (componentValue.value !== newVal) {
                componentValue.value = newVal || '';
            }
        });

        watch(componentValue, (newVal, oldVal) => {
            if (newVal !== propsValue.value && newVal !== oldVal) {
                if (props.range) {
                    emit('update', getValidPeriod(newVal));
                } else {
                    emit('update', newVal);
                }
                emit('update:modelValue', newVal);
            }
        });

        const onSave = () => {
            componentValue.value = datePickerValue.value;
            closeCalendarModal();
        };

        const handleReset = () => {
            componentValue.value = null;
        };

        const setInitialValue = () => {
            componentValue.value = propsValue.value || '';
        };

        const toggleCalendarModal = () => {
            if (!showCalendarModal.value) {
                datePickerValue.value = componentValue.value;
            }
            showCalendarModal.value = !showCalendarModal.value;
        };

        const closeCalendarModal = () => {
            showCalendarModal.value = false;
        };

        const getValidPeriod = (value: any) => {
            if (value?.end) {
                value.end = dayjs(value.end).endOf('day').unix() * 1000;
            }
            return value;
        };

        onMounted(setInitialValue);

        return {
            componentValue,
            datePickerValue,
            showCalendarModal,
            masks,
            formatDate,
            currentLanguage,
            inputValue,
            calendarMode,
            propsValue,
            calendarProps,
            sizeConfig,
            containerStyle,
            resetButtonPosition,
            rules,
            datepickerContainer,
            toggleCalendarModal,
            closeCalendarModal,
            onSave,
            handleReset
        };
    }
});
</script>

<style lang="scss" scoped>
.datepicker__wrapper {
    &:hover .reset__button {
        display: flex;
    }
}
.datepicker__label {
    @apply text-sm font-normal text-[#131416] mb-0.5;
}
.datepicker__container {
    @apply flex items-center relative pr-4  focus-within:border-[#519DF5] rounded-xl gap-1  bg-[#FFFFFF] border hover:border-[#979BB2];

    &.custom-border {
        border-radius: 0 !important;
        border: none;
        border-bottom: 1px solid #c0c2cd;
    }

    &.active-border {
        border-color: #519df5;
    }
}

.datepicker__container-wrapper {
    @apply relative;
}
.datepicker-block {
    @apply flex flex-col;
    &__label {
        @apply text-[12px] text-[#A1A2A4];
    }
    &__input {
        @apply text-[14px] text-[#2E373E];
    }
}
.datepicker-wrapper .vc-popover-caret {
    visibility: hidden;
}
.active-date__input {
    @apply bg-white w-full font-normal text-[#141414] pl-4 pr-2 rounded-xl disabled:text-[#979BB2] bg-inherit;
}
.reset__button {
    position: absolute;

    right: 40px;
    color: #c0c2cd;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &:hover {
        color: #519df5;
    }
}
</style>
