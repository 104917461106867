<template>
    <BaseModalWindow
        v-if="getComponent?.component && !getComponent?.modal && !getComponent?.smart"
        v-bind="getComponent.modalProps"
        @closeModalWindow="closeModal"
        :closeOnClickOutside="true"
        class="z-100">
        <component :is="openComponent.component" @close="closeModal" v-bind="openComponent.props" v-on="openComponent.events" />
    </BaseModalWindow>
    <component v-if="getComponent?.modal && !getComponent?.smart" :is="openComponent.component" @close="closeModal" v-bind="openComponent.props" v-on="openComponent.events" />
    <SmartModal v-if="getComponent?.component && getComponent?.smart" :target="getComponent.target" @close="closeModal">
        <component :is="getComponent.component" v-bind="getComponent.props" @close="closeModal" v-on="getComponent.events" />
    </SmartModal>
</template>

<script>
// import baseCard from "@/components/BaseCard.vue";
import { useMultiModalFormStore } from '@/stores/multiModalForm';
import { mapActions, mapState } from 'pinia';
import BaseModalWindow from '@/components/ui/BaseModalWindow.vue';
import SmartModal from '@/components/ui/SmartModal.vue';

export default {
    name: 'BaseOpenComponent',
    components: { SmartModal, BaseModalWindow },
    props: {},
    emits: [],
    data() {
        return {};
    },
    computed: {
        ...mapState(useMultiModalFormStore, ['openComponent']),
        getComponent() {
            return this.openComponent;
        }
    },
    methods: {
        ...mapActions(useMultiModalFormStore, ['closeOpenComponent']),
        closeModal() {
            this.closeOpenComponent();
        }
    },
    watch: {
        getComponent: {
            handler: function (val) {
                if (val?.component) {
                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });
                }
            },
            deep: true
        }
    },
    created() {},
    updated() {},
    mounted() {}
};
</script>
